import React from 'react';
import '../styles/Header.css'
import AutoNationLogo from "../images/AutoNation Horiz Logo-Color.png";
import { Navbar, Nav } from 'react-bootstrap';

class Header extends React.Component {

  render() {
    return (
      <Navbar expand="lg" className="header" sticky="top">
        <Nav.Link href="https://www.autonation.com/">
          <img className="autonation-icon" alt="AutoNation" src={AutoNationLogo} width="230" height="80" />
        </Nav.Link>
      </Navbar>
    );
  }
}

export default Header;
