import React from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import '../styles/ClaimsForm.css';
import { trackPromise } from 'react-promise-tracker';
import { Button, IconButton } from '@material-ui/core'
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import ReCAPTCHA from 'react-google-recaptcha';

const recaptchaRef = React.createRef();
const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
const site_recaptcha = "6LdyOpgcAAAAANAPUs-WdbGqdvwXvJ3jyy-w7DqG"

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  agreementNumber: yup.string(),
  phoneNumber: yup.string().required("Phone number is required").matches(phoneRegExp, "Not a valid phone number"),
  email: yup.string().required("Email is required").email("Not a valid email"),
  vehicleYear: yup.string().required("Vehicle year is required"),
  vehicleMake: yup.string().required("Vehicle make is required"),
  vehicleModel: yup.string().required("Vehicle model is required"),
  vehicleVin: yup.string().required("Last 6 of VIN is required").min(6, "Must be exactly 6 digits").max(6, "Must be exactly 6 digits"),
  damageDescription: yup.string().required("Damage information is required"),
  fileInput: yup.array().max(3,
    "Too many images, please remove some.").required()
});

class ClaimsForm extends React.Component {

  async handleRequestClaim(values) {

    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    const formData = new FormData();

    const jsonFormData = JSON.stringify({
      "lastName": values.lastName,
      "firstName": values.firstName,
      "agreementNumber": values.agreementNumber,
      "phoneNumber": values.phoneNumber,
      "email": values.email,
      "vehicleYear": values.vehicleYear,
      "vehicleMake": values.vehicleMake,
      "vehicleModel": values.vehicleModel,
      "vehicleVin": values.vehicleVin,
      "reportedDate": values.reportedDate,
      "damageDescription": values.damageDescription,
      "token": token
    })

    formData.append('json', jsonFormData)

    var i;
    for (i = 0; i < values.fileInput.length; i++) { // for each inputted file
      formData.append('file' + i, values.fileInput[i])
    }
    const response = await trackPromise(
      fetch("/email", {
        method: 'POST',
        body: formData
      })
    )

    if (response.ok === true) { // true response means successfully sent email
      this.props.history.push("/confirmation")
    } else {                    // false response means failed to send
      this.props.history.push("/error")
    }
  };

  render() {
    return (
      <div className="quote-page">
        <div className="title">
          <h3>Dent Repair Claim Request Form</h3>
        </div>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => this.handleRequestClaim(values)}
          initialValues={{
            reportedDate: "",
            firstName: "",
            lastName: "",
            agreementNumber: "",
            phoneNumber: "",
            phoneExtension: "",
            email: "",
            vehicleYear: "",
            vehicleMake: "",
            vehicleModel: "",
            vehicleVin: "",
            sellingDealer: "",
            damageDescription: "",
            fileInput: []
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            setFieldValue
          }) => (
              <Form noValidate className="quote-form" onSubmit={handleSubmit}>
                <div className="sub-form">
                  <div className="sub-form-outer">
                    <PermContactCalendarIcon className="icon" />
                    <b className="sub-title">Contact Information</b>
                  </div>
                  <Container className="sub-form-inner">
                    {/* Contact Information sub form*/}
                    {/* First Name field*/}
                    <Row>
                      <Form.Group
                        className="input-form" as={Col} md="3"
                        controlId="validation01">
                        <Form.Label className="label">
                          First Name *
                        </Form.Label>
                        <Form.Label className="required-label">
                        </Form.Label>
                        <Form.Control
                          className="input"
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          onChange={handleChange}
                          value={values.firstName}
                          isInvalid={touched.firstName && !!errors.firstName}
                          isValid={touched.firstName && !errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {/* Last Name field*/}
                      <Form.Group
                        className="input-form" as={Col} md="3"
                        controlId="validation02">
                        <Form.Label className="label">Last Name *</Form.Label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          onChange={handleChange}
                          value={values.lastName}
                          isInvalid={touched.lastName && !!errors.lastName}
                          isValid={touched.lastName && !errors.lastName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {/* Agreement Number field*/}
                      <Form.Group
                        className="input-form" as={Col} md="6"
                        controlId="validation03">
                        <Form.Label className="label">Agreement Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="agreementNumber"
                          placeholder="If available"
                          onChange={handleChange}
                          value={values.agreementNumber}
                          isInvalid={touched.agreementNumber && !!errors.agreementNumber}
                          isValid={touched.agreementNumber && !errors.agreementNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.agreementNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      {/* Phone Number field*/}
                      <Form.Group
                        className="input-form" as={Col} md="3"
                        controlId="validation04">
                        <Form.Label className="label">Phone Number *</Form.Label>
                        <Form.Control
                          type="text"
                          name="phoneNumber"
                          placeholder="Phone Number"
                          onChange={handleChange}
                          value={values.phoneNumber}
                          isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                          isValid={touched.phoneNumber && !errors.phoneNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phoneNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="input-form" as={Col} md="5"
                        controlId="validation06">
                        <Form.Label className="label">Email *</Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="Email"
                          onChange={handleChange}
                          value={values.email}
                          isInvalid={touched.email && !!errors.email}
                          isValid={touched.email && !errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                  </Container>
                </div>
                {/* Vehicle Information sub form */}
                <div className="sub-form">
                  <div className="sub-form-outer">
                    <DriveEtaIcon className="icon" />
                    <b className="sub-title">Vehicle Information</b>
                  </div>
                  {/* Vehicle Year field*/}
                  <Container className="sub-form-inner">
                    <Row>
                      <Form.Group
                        className="input-form" as={Col} md="3"
                        controlId="validation08">
                        <Form.Label className="label">Vehicle Year *</Form.Label>
                        <Form.Control
                          type="text"
                          name="vehicleYear"
                          placeholder="Vehicle Year"
                          onChange={handleChange}
                          value={values.vehicleYear}
                          isInvalid={touched.vehicleYear && !!errors.vehicleYear}
                          isValid={touched.vehicleYear && !errors.vehicleYear}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.vehicleYear}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {/* Vehicle Make field*/}
                      <Form.Group
                        className="input-form" as={Col} md="3"
                        controlId="validation09">
                        <Form.Label className="label">Vehicle Make *</Form.Label>
                        <Form.Control
                          type="text"
                          name="vehicleMake"
                          placeholder="Vehicle Make"
                          onChange={handleChange}
                          value={values.vehicleMake}
                          isInvalid={touched.vehicleMake && !!errors.vehicleMake}
                          isValid={touched.vehicleMake && !errors.vehicleMake}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.vehicleMake}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {/* Vehicle model field*/}
                      <Form.Group
                        className="input-form" as={Col} md="3"
                        controlId="validation10">
                        <Form.Label className="label">Vehicle Model *</Form.Label>
                        <Form.Control
                          type="text"
                          name="vehicleModel"
                          placeholder="Vehicle Model"
                          onChange={handleChange}
                          value={values.vehicleModel}
                          isInvalid={touched.vehicleModel && !!errors.vehicleModel}
                          isValid={touched.vehicleModel && !errors.vehicleModel}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.vehicleModel}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {/* Vehicle VIN field*/}
                      <Form.Group
                        className="input-form" as={Col} md="3"
                        controlId="validation11">
                        <Form.Label className="label">Last 6 of VIN *</Form.Label>
                        <Form.Control
                          type="text"
                          name="vehicleVin"
                          placeholder="Last 6 of VIN"
                          onChange={handleChange}
                          value={values.vehicleVin}
                          isInvalid={touched.vehicleVin && !!errors.vehicleVin}
                          isValid={touched.vehicleVin && !errors.vehicleVin}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.vehicleVin}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                    </Row>
                  </Container>
                </div>
                {/*Damage Information sub form*/}
                <div className="sub-form">
                  <div className="sub-form-outer">
                    <FlashOnIcon className="icon" />
                    <b className="sub-title">Damage Information</b>
                  </div>
                  <Container className="sub-form-inner-bottom">
                    <Row>
                      {/* Damage Description field*/}
                      <Form.Group
                        className="input-form" as={Col} md="12"
                        controlId="validation13">
                        <Form.Label className="label">Damage Description *</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="damageDescription"
                          placeholder="Describe the location and size of damage"
                          onChange={handleChange}
                          value={values.damageDescription}
                          isInvalid={touched.damageDescription && !!errors.damageDescription}
                          isValid={touched.damageDescription && !errors.damageDescription}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.damageDescription}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      {/* File field*/}
                      <Form.Label className="label">Include images of the damage</Form.Label>
                      <label htmlFor="contained-button-file">
                      </label>
                      <input accept="image/*"
                        className="image-input"
                        id="icon-button-file"
                        type="file"
                        name="fileInput"
                        onChange={e => (e.target.files[0] !== undefined ?
                          setFieldValue('fileInput', values.fileInput.concat(e.target.files[0])) : console.log())} />
                      <label htmlFor="icon-button-file">
                        <Button
                          color="primary"
                          variant="contained"
                          disableElevation
                          size="large"
                          aria-label="upload picture"
                          component="span"
                          startIcon={<PhotoCamera />}
                          classes={{ root: "upload-button", label: "upload-button-text" }}
                        >
                          Upload
                        </Button>
                      </label>
                      <p className="custom-error">
                        {touched.vehicleModel ? errors.fileInput : console.log()}
                      </p>
                      <div className="uploaded-images-div">
                        {values.fileInput.map(d =>
                          <div key={d.name} className="image-with-remove-icon">
                            <img className="uploaded-image"
                              src={URL.createObjectURL(d)}
                              alt="upload" />
                            <IconButton
                              aria-label="delete"
                              className="x-button"
                              size="small"
                              onClick={() => (
                                setFieldValue('fileInput', values.fileInput.filter(x => x !== d)))
                              }>
                              <CancelOutlined classes={{ root: "delete-button" }} />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    </Row>
                  </Container>
                </div>
                {/* overall form submission button */}
                <div className="col text-center">
                  <ReCAPTCHA className="recaptcha"
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={site_recaptcha} />
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    classes={{ root: "claims-button", label: "claims-button-text" }}>
                    Request Repair
                </Button>
                </div>
                <p className="note">
                  * Denotes a required field
                </p>
              </Form>
            )
          }
        </Formik>
      </div >
    );
  }
}

export default withRouter(ClaimsForm);
