import React from 'react';
import '../styles/Confirmation.css'
import { withRouter } from 'react-router-dom';

class Confirmation extends React.Component {

  render() {
    return (
      <div className="confirmation">
        <div className="confirmation-box">
          <span className="text-overflow-center">
            <h1>
              Thanks!
            </h1>
          </span>
          <br />
          <span className="text-overflow-center">
            <h2>
              Your claim request has been submitted and is under review.
            </h2>
          </span>
          <br />
          <span className="text-overflow-center">
            <h3>
              A claims representative will contact you within one business day to finalize the repair process.
            </h3>
          </span>
        </div>
      </div >
    );
  }
}

export default withRouter(Confirmation);
