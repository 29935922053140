import React from 'react';
import '../styles/Error.css'
import { withRouter } from 'react-router-dom';

class Error extends React.Component {

  render() {
    return (
      <div className="error">
        <div className="error-box">
          <span className="text-overflow-center">
            <h1>
              Sorry!
            </h1>
          </span>
          <br />
          <span className="text-overflow-center">
            <h2>
              There was an error submitting your claim request.
            </h2>
          </span>
          <br />
        </div>
      </div >
    );
  }
}

export default withRouter(Error);
